import { BrandAmpersand, BrandName, BrandWrapper } from './Brand.styled';

function Brand() {
  return (
    <BrandWrapper>
      <BrandName>Ani</BrandName>
      <BrandAmpersand />
      <BrandName>Nacho</BrandName>
    </BrandWrapper>
  );
}

export default Brand;
