import styled from 'styled-components';

import Container from '../../components/Container/Container.component';

export const MomentsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  margin-bottom: 2rem;
`;
