import { useEffect, useState } from 'react';

import useGuests from '../../hooks/useGuests.hook';

import FormGroup from '../FormGroup/FormGroup.component';
import Input from '../Input/Input.component';
import {
  ConfirmationFormWrapper,
  FirstStepForm,
  FormButton,
} from './ConfirmationForm.styled';
import Guests from './Guests/Guests.component';

function ConfirmationForm() {
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(null), 3000);
    }
  }, [error]);

  const [groupCode, setGroupCode] = useState('');

  const { filteredGuests, validateGroup } = useGuests(groupCode);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const rawGroupCode: string = e.currentTarget.group_code.value;
    const groupCode = rawGroupCode.toUpperCase();

    const isValidGroup = validateGroup(groupCode);

    if (isValidGroup) {
      setGroupCode(groupCode);
    } else {
      setError('¡Ups! Parece que el código de grupo no es válido.');
    }
  };

  const showFirstStep = !filteredGuests?.length;
  const showSecondStep = !showFirstStep;

  return (
    <ConfirmationFormWrapper>
      {/* Step 1 */}
      {showFirstStep && (
        <FirstStepForm onSubmit={onSubmit}>
          <div>
            Ingresá el <b>código de grupo</b> que recibiste junto a la
            invitación.
          </div>

          <FormGroup label="Código de grupo">
            <Input
              name="group_code"
              pattern="\w{6}"
              placeholder="Ej. IJD8DY"
              type="text"
              required
            />
          </FormGroup>

          {error && <div>{error}</div>}

          <FormButton type="submit">Continuar</FormButton>
        </FirstStepForm>
      )}

      {/* Step 2 */}
      {showSecondStep && <Guests guests={filteredGuests} />}
    </ConfirmationFormWrapper>
  );
}

export default ConfirmationForm;
