import AddGuestForm from '../../components/AddGuestForm/AddGuestForm.component';
import Container from '../../components/Container/Container.component';
import Section from '../../components/Section/Section.component';

function AddGuests() {
  return (
    <Section>
      <Container>
        <AddGuestForm />
      </Container>
    </Section>
  );
}

export default AddGuests;
