import { ButtonWrapper } from './Button.styled';

type IButtonProps = React.ComponentPropsWithRef<'button'> & {
  rounded?: boolean;
};

function Button({ rounded = false, ...otherProps }: IButtonProps) {
  return <ButtonWrapper rounded={rounded} {...otherProps} />;
}

export default Button;
