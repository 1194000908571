import styled, { css } from 'styled-components';

export const PolaroidWrapper = styled.div<{
  rotate?: 'clockwise' | 'counterclockwise';
}>(
  ({ rotate }) => css`
    aspect-ratio: 88 / 107;
    width: 88mm;
    max-width: 100%;
    margin: 0 auto;

    ${rotate &&
    css`
      transform: rotateZ(${rotate === 'clockwise' ? '2deg' : '-2deg'});
    `}
  `,
);

const gap = (4.5 * 100) / 88 + '%';

export const PolaroidPane = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${gap};
    height: 100%;
    padding: ${gap};
    border-radius: 2px;
    background-color: ${theme.colors.light};
    box-shadow: 2px 2px 16px ${theme.colors.broomGreen + '33'};
  `,
);

export const PolaroidFrame = styled.img(
  ({ theme }) => css`
    display: block;
    aspect-ratio: 79 / 79;
    width: 100%;
    object-fit: cover;
    border-radius: 2px;
    background-color: ${theme.colors.brideWhite};
  `,
);

export const PolaroidMessage = styled.p`
  margin: 0;
`;
