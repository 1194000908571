import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.button<{ rounded: boolean }>(
  ({ theme, rounded }) => css`
    padding: 0.5rem 2rem;
    font-size: 1rem;
    color: ${theme.colors.brideWhite};
    background-color: ${theme.colors.broomGreen};

    ${rounded &&
    css`
      border-radius: 50vh;
    `}

    :hover {
      opacity: 0.75;
    }
  `,
);
