import breakpoints from './breakpoints.constants';

const X_SMALL = 'xSmall';
const SMALL = 'small';
const MEDIUM = 'medium';
const LARGE = 'large';
const X_LARGE = 'xLarge';

export type IDeviceSize =
  | typeof X_SMALL
  | typeof SMALL
  | typeof MEDIUM
  | typeof LARGE
  | typeof X_LARGE;

const devices: Record<IDeviceSize, string> = {
  xSmall: `min-width: ${breakpoints.xSmall.min}px`,
  small: `min-width: ${breakpoints.small.min}px`,
  medium: `min-width: ${breakpoints.medium.min}px`,
  large: `min-width: ${breakpoints.large.min}px`,
  xLarge: `min-width: ${breakpoints.xLarge.min}px`,
};

export default devices;
