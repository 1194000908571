import Lottie from '../../components/Lottie/Lottie.component';
import Section from '../../components/Section/Section.component';
import { SectionHeading } from '../../components/Section/Section.styled';
import Swiper from '../../components/Swiper/Swiper.component';

import cameraAnimation from '../../lottie/camera-animation.json';

import { MomentsContainer } from './Moments.styled';

function Moments() {
  return (
    <Section>
      <MomentsContainer>
        <SectionHeading withBackground withBorder>
          Nuestros momentos
        </SectionHeading>
        <Lottie animationData={cameraAnimation} loop={false} />
      </MomentsContainer>

      <Swiper />
    </Section>
  );
}

export default Moments;
