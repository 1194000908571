import styled, { css } from 'styled-components';

export const GuestItemWrapper = styled.li`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const GuestName = styled.h4(
  ({ theme }) => css`
    margin: 0;
    font-family: ${theme.fonts.parisienne};
    font-size: 1.75rem;
    font-weight: normal;
  `,
);

export const GuestItemForm = styled.form(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    background-color: ${theme.colors.broomGreen + '40'};
  `,
);

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const AssistanceOption = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
