import styled from 'styled-components';

import Container from '../../components/Container/Container.component';
import {
  SectionContent,
  SectionInner,
  SectionSubheading,
} from '../../components/Section/Section.styled';

import devices from '../../constants/devices.constants';

export const CeremonyContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
`;

export const CeremonyDatetimeWrapper = styled(SectionInner)``;

export const CeremonyDate = styled(SectionSubheading)``;

export const CeremonyTime = styled(SectionContent)``;

export const CeremonyPlaceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  @media (${devices.large}) {
    flex-direction: row;
    justify-content: space-between;
    gap: 8rem;
  }
`;

export const CeremonyPlaceInner = styled(SectionInner)``;

export const CeremonyPlaceName = styled(SectionSubheading)``;

export const CeremonyPlaceAddress = styled(SectionContent)``;
