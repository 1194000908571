import { useEffect, useState } from 'react';

import {
  TimerBackground,
  TimerItem,
  TimerItemLabel,
  TimerItemValue,
  TimerWrapper,
} from './Timer.styled';

function calculateTimeLeft(targetDate: Date) {
  const differenceInMs = +targetDate - +new Date();

  return differenceInMs > 0
    ? {
        days: Math.floor(differenceInMs / (1000 * 60 * 60 * 24)),
        hours: Math.floor((differenceInMs / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((differenceInMs / 1000 / 60) % 60),
        seconds: Math.floor((differenceInMs / 1000) % 60),
      }
    : null;
}

type ITimeLeft = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

type ITimerProps = React.ComponentPropsWithRef<'div'> & {
  targetDate: Date;
};

function Timer({ targetDate, ...otherProps }: ITimerProps) {
  const [timeLeft, setTimeLeft] = useState<ITimeLeft | null>(
    calculateTimeLeft(targetDate),
  );

  useEffect(() => {
    const id = setTimeout(
      () => setTimeLeft(calculateTimeLeft(targetDate)),
      1000,
    );

    return () => clearTimeout(id);
  });

  return (
    <>
      {timeLeft ? (
        <TimerWrapper {...otherProps}>
          <TimerBackground src="/img/floral-frame.svg" alt="" />

          <TimerItem>
            <TimerItemValue>{timeLeft.days}</TimerItemValue>
            <TimerItemLabel>días</TimerItemLabel>
          </TimerItem>

          <TimerItem>
            <TimerItemValue>{timeLeft.hours}</TimerItemValue>
            <TimerItemLabel>hs</TimerItemLabel>
          </TimerItem>

          <TimerItem>
            <TimerItemValue>{timeLeft.minutes}</TimerItemValue>
            <TimerItemLabel>min</TimerItemLabel>
          </TimerItem>

          <TimerItem>
            <TimerItemValue>{timeLeft.seconds}</TimerItemValue>
            <TimerItemLabel>seg</TimerItemLabel>
          </TimerItem>
        </TimerWrapper>
      ) : null}
    </>
  );
}

export default Timer;
