import styled, { css } from 'styled-components';

export const AnchorWrapper = styled.a(
  ({ theme }) => css`
    padding-bottom: 0.5rem;
    border-bottom: 1px solid transparent;

    :hover {
      color: ${theme.colors.redwood};
      border-color: ${theme.colors.redwood};
    }
  `,
);
