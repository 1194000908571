import {
  PaymentMethodInfo,
  PaymentMethodName,
  PaymentMethodValue,
  PaymentMethodWrapper,
} from './PaymentMethod.styled';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

type IPaymentMethodProps = {
  info: string;
  name: string;
  value: string;
};

function PaymentMethod({ info, name, value }: IPaymentMethodProps) {
  const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const targetValue = e.currentTarget.innerText.replace(/\s+/g, '');

    navigator.clipboard.writeText(targetValue);
  };

  return (
    <PaymentMethodWrapper>
      <PaymentMethodName>{name}</PaymentMethodName>
      <PaymentMethodInfo>{info}</PaymentMethodInfo>
      <Tippy
        trigger="click"
        content="¡Copiado!"
        onShown={(instance) => setTimeout(() => instance.hide(), 2000)}
      >
        <PaymentMethodValue onClick={onClick}>
          <span>{value}</span>
          <i className="fa-regular fa-copy"></i>
        </PaymentMethodValue>
      </Tippy>
    </PaymentMethodWrapper>
  );
}

export default PaymentMethod;
