import styled, { css } from 'styled-components';

import Polaroid from '../Polaroid/Polaroid.component';
import { Swiper } from 'swiper/react';

export const SwiperWrapper = styled(Swiper)(
  ({ theme }) => css`
    padding: 2rem 0;

    .swiper-wrapper {
      margin-bottom: 2rem;
    }

    /* Swiper varibales */
    --swiper-pagination-bullet-horizontal-gap: 0.5rem;
    --swiper-pagination-bullet-width: 0.75rem;
    --swiper-pagination-bullet-height: 0.75rem;
    --swiper-theme-color: ${theme.colors.broomGreen};
  `,
);

export const SwiperPolaroid = styled(Polaroid)<{
  isActive: boolean;
  isNext: boolean;
}>(({ isActive, isNext }) => {
  const rotateValue = (isNext ? -2 : 2) + 'deg';

  return css`
    transform: rotate(${rotateValue}) scale(0.75);
    opacity: 0.75;

    /* Transitions */
    transition-property: transform, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    ${isActive &&
    css`
      transform: scale(1);
      opacity: 1;
    `}
  `;
});
