import { guestsCollectionRef } from '../../services/firebase.service';
import Button from '../Button/Button.component';
import FormGroup from '../FormGroup/FormGroup.component';
import Input from '../Input/Input.component';
import { AddGuestFormWrapper } from './AddGuestForm.styled';
import { addDoc } from 'firebase/firestore';

function AddGuestForm() {
  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const formElement = e.target as HTMLFormElement;

    const guestData = {
      first_name: e.currentTarget.first_name.value,
      group_code: e.currentTarget.group_code.value,
      last_name: e.currentTarget.last_name.value,
    };

    try {
      await addDoc(guestsCollectionRef, guestData);

      formElement.reset();
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <AddGuestFormWrapper onSubmit={onSubmit}>
      <FormGroup label="Nombre">
        <Input
          name="first_name"
          placeholder="Ej. Ignacio"
          type="text"
          required
        />
      </FormGroup>

      <FormGroup label="Apellido">
        <Input name="last_name" placeholder="Ej. Vallejos" type="text" />
      </FormGroup>

      <FormGroup label="Código de grupo">
        <Input
          name="group_code"
          pattern="\w{6}"
          placeholder="Ej. IJD8DY"
          type="text"
          required
        />
      </FormGroup>

      <Button type="submit">Agregar</Button>
    </AddGuestFormWrapper>
  );
}

export default AddGuestForm;
