import styled, { css } from 'styled-components';

import devices from '../../constants/devices.constants';

import Button from '../Button/Button.component';

export const ConfirmationFormWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    padding: 2rem;
    border: 2px solid ${theme.colors.broomGreen};

    @media (${devices.medium}) {
      max-width: 75%;
    }

    @media (${devices.large}) {
      max-width: 50%;
    }
  `,
);

export const FirstStepForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const FormButton = styled(Button)``;
