import styled, { css } from 'styled-components';

export const PaymentMethodWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const PaymentMethodName = styled.h3(
  ({ theme }) => css`
    margin: 0;
    font-weight: normal;
    font-size: 1.25rem;
    color: ${theme.colors.redwood};
  `,
);

export const PaymentMethodInfo = styled.div``;

export const PaymentMethodValue = styled.button(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
    border: 1px solid ${theme.colors.broomGreen};
    background-color: ${theme.colors.light};

    > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `,
);
