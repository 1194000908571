import Lottie from '../../components/Lottie/Lottie.component';
import PaymentMethod from '../../components/PaymentMethod/PaymentMethod.component';
import Section from '../../components/Section/Section.component';
import { SectionHeading } from '../../components/Section/Section.styled';

import giftsAnimation from '../../lottie/gift-animation.json';

import {
  GiftsContainer,
  GiftsContent,
  GiftsInner,
  PaymentMethodsWrapper,
} from './Gifts.styled';

function Gifts() {
  return (
    <Section>
      <GiftsContainer>
        <SectionHeading withBackground withBorder>
          Regalos
        </SectionHeading>

        <GiftsInner>
          <Lottie animationData={giftsAnimation} />

          <GiftsContent>
            <span>
              ¡El mejor regalo es que vengas a divertirte y compartir con
              nosotros!
            </span>{' '}
            Si quisieras regalarnos algo más que tu presencia, vamos a
            disfrutarlo en nuestra luna de miel.
          </GiftsContent>
        </GiftsInner>

        <PaymentMethodsWrapper>
          <PaymentMethod name="Argentina" info="Alias" value="ANI.NACHO.MP" />
          <PaymentMethod
            name="Europa"
            info="IBAN"
            value="DE06 1001 1001 2622 6209 10"
          />
          <PaymentMethod
            name="Binance"
            info="BNB Smart Chain (BEP20)"
            value="0x52e507e955726a248cc8c5b92355e3e842a5429d"
          />
        </PaymentMethodsWrapper>
      </GiftsContainer>
    </Section>
  );
}

export default Gifts;
