const slides = [
  {
    id: '1',
    message: '25/8/2018',
    src: '/img/moments/2018-08-25 puerto de olivos-min.jpg',
  },
  {
    id: '2',
    message: '4/11/2018',
    src: '/img/moments/2018-11-04 reserva ecologica-min.jpg',
  },
  {
    id: '3',
    message: '3/2/2019',
    src: '/img/moments/2019-02-03 bosques de la plata-min.jpg',
  },
  {
    id: '4',
    message: '29/12/2019',
    src: '/img/moments/2019-12-29 cataratas del iguazu-min.jpg',
  },
  {
    id: '5',
    message: '8/10/2021',
    src: '/img/moments/2021-10-08 bariloche-min.jpg',
  },
  {
    id: '6',
    message: '14/8/2022',
    src: '/img/moments/2022-08-14 plaza san marcos-min.jpg',
  },
  {
    id: '7',
    message: '14/8/2022',
    src: '/img/moments/2022-08-14 puente de los suspiros-min.jpg',
  },
  {
    id: '8',
    message: '19/8/2022',
    src: '/img/moments/2022-08-19 florencia-min.jpg',
  },
  {
    id: '9',
    message: '21/8/2022',
    src: '/img/moments/2022-08-21 monterosso-min.jpg',
  },
  {
    id: '10',
    message: '23/8/2022',
    src: '/img/moments/2022-08-23 vernazza-min.jpg',
  },
  {
    id: '11',
    message: '24/8/2022',
    src: '/img/moments/2022-08-24 pisa-min.jpg',
  },
  {
    id: '12',
    message: '26/8/2022',
    src: '/img/moments/2022-08-26 grosseto-min.jpg',
  },
  {
    id: '13',
    message: '31/8/2022',
    src: '/img/moments/2022-08-31 coliseo-min.jpg',
  },
  {
    id: '14',
    message: '2/1/2023',
    src: '/img/moments/2023-01-02 cozumel-min.jpg',
  },
  {
    id: '15',
    message: '5/1/2023',
    src: '/img/moments/2023-01-05 tulum-min.jpg',
  },
  {
    id: '16',
    message: '12/1/2023',
    src: '/img/moments/2023-01-12 cancun-min.jpg',
  },
  {
    id: '17',
    message: '19/7/2023',
    src: '/img/moments/2023-07-19 dresde-min.jpg',
  },
  {
    id: '18',
    message: '20/7/2023',
    src: '/img/moments/2023-07-20 radebeul-min.jpg',
  },
  {
    id: '19',
    message: '22/7/2023',
    src: '/img/moments/2023-07-22 praga-min.jpg',
  },
];

export default slides;
