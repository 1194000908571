import Lottie from '../../components/Lottie/Lottie.component';
import Section from '../../components/Section/Section.component';
import { SectionHeading } from '../../components/Section/Section.styled';

import toastAnimation from '../../lottie/toast-animation.json';

import {
  DressCodeContainer,
  DressCodeDetailsSubtitle,
  DressCodeDetailsWrappper,
  DressCodeImagesWrapper,
} from './DressCode.styled';

function DressCode() {
  return (
    <Section>
      <DressCodeContainer>
        <SectionHeading withBorder>Código de vestimenta</SectionHeading>

        <DressCodeDetailsWrappper>
          <Lottie animationData={toastAnimation} />
          <DressCodeDetailsSubtitle>Formal</DressCodeDetailsSubtitle>
        </DressCodeDetailsWrappper>

        <DressCodeImagesWrapper>
          <img src="/img/dress-code-women-min.png" alt="" />
          <img src="/img/dress-code-men-min.png" alt="" />
        </DressCodeImagesWrapper>
      </DressCodeContainer>
    </Section>
  );
}

export default DressCode;
