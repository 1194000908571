import Container from '../../components/Container/Container.component';
import LoginForm from '../../components/LoginForm/LoginForm.component';
import Section from '../../components/Section/Section.component';

function Login() {
  return (
    <Section>
      <Container>
        <LoginForm />
      </Container>
    </Section>
  );
}

export default Login;
