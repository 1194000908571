import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Lottie from '../Lottie/Lottie.component';

export const MenuWrapper = styled.nav`
  position: fixed;
  z-index: 100;
  width: 100%;
  padding: 2rem 0;
`;

export const MenuOverlayInner = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    color: ${theme.colors.brideWhite};
    background-color: ${theme.colors.broomGreen};
  `,
);

export const MenuLottie = styled(Lottie)(
  ({ theme }) => css`
    max-width: 265px;
    height: auto;

    * {
      fill: ${theme.colors.brideWhite};
      stroke: ${theme.colors.brideWhite};
    }
  `,
);

export const MenuItems = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
`;

export const MenuItemLink = styled(Link)(
  ({ theme }) => css`
    display: block;
    padding: 1rem 0;
    font-size: 1.25rem;

    :hover {
      color: ${theme.colors.redwood};
    }
  `,
);
