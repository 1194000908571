import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import routes from '../../constants/routes.constants';

import useAuth from '../../hooks/useAuth.hook';
import useBlockScroll from '../../hooks/useBlockScroll.hook';

import floatingHeartsAnimation from '../../lottie/floating-hearts-animation.json';

import Container from '../Container/Container.component';
import DarkOverlay from '../DarkOverlay/DarkOverlay.component';
import FadeTransition from '../FadeTransition/FadeTransition.component';
import {
  MenuItemLink,
  MenuItems,
  MenuLottie,
  MenuOverlayInner,
  MenuWrapper,
} from './Menu.styled';
import MenuToggle from './MenuToggle/MenuToggle.component';

function Menu() {
  const { pathname } = useLocation();

  const [isOpened, setIsOpened] = useState(false);

  useBlockScroll(isOpened);

  const closeMenu = () => setIsOpened(false);
  const openMenu = () => setIsOpened(true);

  const { user } = useAuth();

  const menuItems = user ? routes : routes.filter((route) => !route.isPrivate);

  return (
    <MenuWrapper>
      <Container>
        <MenuToggle
          onClick={openMenu}
          variant={pathname === '/' ? 'light' : 'primary'}
        />

        <FadeTransition in={isOpened}>
          <DarkOverlay onClickOutside={closeMenu}>
            <MenuOverlayInner>
              <MenuLottie
                animationData={floatingHeartsAnimation}
                loop={!isOpened}
              />

              <MenuItems>
                {menuItems.map((menuItem) => (
                  <li key={menuItem.path}>
                    <MenuItemLink onClick={closeMenu} to={menuItem.path}>
                      {menuItem.title}
                    </MenuItemLink>
                  </li>
                ))}
              </MenuItems>
            </MenuOverlayInner>
          </DarkOverlay>
        </FadeTransition>
      </Container>
    </MenuWrapper>
  );
}

export default Menu;
