import { LottieComponentProps } from 'lottie-react';

import { LottieWrapper } from './Lottie.styled';

type ILottieProps = Omit<LottieComponentProps, 'ref' | 'as'>;

function Lottie({ loop = true, ...otherProps }: ILottieProps) {
  return <LottieWrapper loop={loop} {...otherProps} />;
}

export default Lottie;
