import styled, { css } from 'styled-components';

import devices from '../../constants/devices.constants';

export const BrandWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    color: ${theme.colors.brideWhite};

    @media (${devices.medium}) {
      flex-direction: row;
      gap: 2rem;
    }
  `,
);

export const BrandName = styled.span(
  ({ theme }) => css`
    font-family: ${theme.fonts.parisienne};
    font-size: 4rem;

    @media (${devices.medium}) {
      font-size: 6rem;
    }
  `,
);

export const BrandAmpersand = styled.span(
  ({ theme }) => css`
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: ${theme.colors.broomGreen + 'bf'};

    ::after {
      content: '&';
      font-size: 2rem;
    }
  `,
);
