import styled, { css } from 'styled-components';

import baseTheme from '../../../styles/baseTheme';

import Lottie from '../../Lottie/Lottie.component';

export type IMenuToggleVariant = 'light' | 'primary';

const BACKGROUND_COLOR: Record<IMenuToggleVariant, string> = {
  light: baseTheme.colors.brideWhite,
  primary: baseTheme.colors.broomGreen,
};

const FILL_STROKE_COLOR: Record<IMenuToggleVariant, string> = {
  light: baseTheme.colors.dark,
  primary: baseTheme.colors.brideWhite,
};

export const MenuToggleWrapper = styled.button<{ variant: IMenuToggleVariant }>(
  ({ variant }) => css`
    display: block;
    width: 3rem;
    height: 3rem;
    margin-left: auto;
    border-radius: 50%;
    background-color: ${BACKGROUND_COLOR[variant] + 'bf'};

    :hover {
      background-color: ${BACKGROUND_COLOR[variant]};
    }
  `,
);

export const MenuToggleLottie = styled(Lottie)<{ variant: IMenuToggleVariant }>(
  ({ variant }) => css`
    height: 100%;

    * {
      fill: ${FILL_STROKE_COLOR[variant]};
      stroke: ${FILL_STROKE_COLOR[variant]};
    }
  `,
);
