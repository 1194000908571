import styled from 'styled-components';

import Container from '../../components/Container/Container.component';
import {
  SectionInner,
  SectionSubheading,
} from '../../components/Section/Section.styled';

export const DressCodeContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
`;

export const DressCodeDetailsWrappper = styled(SectionInner)``;

export const DressCodeDetailsSubtitle = styled(SectionSubheading)``;

export const DressCodeImagesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 2rem;

  > img {
    display: block;
    width: 130px;
    height: 320px;
    object-fit: contain;

    :last-child {
      object-position: right;
    }
  }
`;
