import styled, { css } from 'styled-components';

import Container from '../../components/Container/Container.component';
import { SectionContent } from '../../components/Section/Section.styled';

export const GuestsListContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
`;

export const GuestsListInfo = styled(SectionContent)`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

export const GuestsListInfoLabel = styled.label<{ isSelected: boolean }>(
  ({ theme, isSelected }) => css`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${isSelected ? theme.colors.redwood : theme.colors.dark};
  `,
);

export const GuestsListTable = styled.table(
  ({ theme }) => css`
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;

    > thead {
      > tr {
        background-color: ${theme.colors.broomGreen + '80'};

        > th {
          padding: 0.5rem 1rem;
        }
      }
    }

    > tbody {
      > tr {
        > td {
          padding: 0.5rem 1rem;
        }

        :nth-child(even) {
          background-color: ${theme.colors.broomGreen + '80'};
        }
      }
    }
  `,
);
