import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';

import {
  ITransitionProps,
  ITransitionStyles,
} from '../../types/transition.types';

type IFadeTransitionProps = ITransitionProps & {
  positionAbsolute?: boolean;
};

const FadeTransition = ({
  children,
  in: inProp,
  positionAbsolute,
  timeout = 300,
}: IFadeTransitionProps) => {
  const defaultStyles: React.CSSProperties = {
    position: positionAbsolute ? 'absolute' : 'static',
    opacity: 0,
    transitionProperty: 'opacity',
    transitionDuration: `${timeout}ms`,
    transitionTimingFunction: 'ease-in-out',
  };

  const transitionStyles: ITransitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
  };

  const nodeRef = useRef<HTMLDivElement | null>(null);

  return (
    <Transition
      appear
      in={inProp}
      nodeRef={nodeRef}
      timeout={timeout}
      unmountOnExit
    >
      {(status) => (
        <div
          ref={nodeRef}
          style={{ ...defaultStyles, ...transitionStyles[status] }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

export default FadeTransition;
