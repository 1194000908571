import { useState } from 'react';

import { QueryDocumentSnapshot } from 'firebase/firestore';

export type IAssistanceFilterOptionId =
  | 'all-guests'
  | 'assisting-guests'
  | 'civil-guests'
  | 'not-assisting-guests'
  | 'not-confirmed-guests';

export const assistanceFiltersOptionsLabels: Record<
  IAssistanceFilterOptionId,
  string
> = {
  'all-guests': 'Todos',
  'assisting-guests': 'Asisten',
  'civil-guests': 'Civil',
  'not-assisting-guests': 'No asisten',
  'not-confirmed-guests': 'Pendientes de confirmación',
};

function useAssistanceFilters(allGuests?: QueryDocumentSnapshot[]) {
  const [selectedOptionId, setSelectedOption] =
    useState<IAssistanceFilterOptionId>('all-guests');

  const assistingGuests = allGuests?.filter((guestDoc) => {
    return guestDoc.data().will_assist === true;
  });

  const civilGuests = allGuests?.filter((guestDoc) => {
    return guestDoc.data().civil === true;
  });

  const notAssistingGuests = allGuests?.filter((guestDoc) => {
    return guestDoc.data().will_assist === false;
  });

  const notConfirmedGuests = allGuests?.filter((guestDoc) => {
    const guestData = guestDoc.data();
    const isConfirmed =
      guestData.will_assist === true || guestData.will_assist === false;

    return !isConfirmed;
  });

  const filteredGuests: Record<
    IAssistanceFilterOptionId,
    QueryDocumentSnapshot[] | undefined
  > = {
    'all-guests': allGuests,
    'assisting-guests': assistingGuests,
    'civil-guests': civilGuests,
    'not-assisting-guests': notAssistingGuests,
    'not-confirmed-guests': notConfirmedGuests,
  };

  const updateAssistanceFilter = (
    selectedOptionId: IAssistanceFilterOptionId,
  ) => {
    setSelectedOption(selectedOptionId);
  };

  return {
    filteredGuests,
    selectedOptionId,
    updateAssistanceFilter,
  };
}

export default useAssistanceFilters;
