import styled, { css } from 'styled-components';

import devices from '../../constants/devices.constants';

export const DarkOverlayWrapper = styled.div(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.dark + 'bf'};
  `,
);

export const DarkOverlayInner = styled.div(
  ({ theme }) => css`
    width: 100%;
    max-height: 100%;
    overflow-y: scroll;
    background-color: ${theme.colors.light};

    @media (${devices.medium}) {
      max-width: 75%;
    }

    @media (${devices.large}) {
      max-width: 50%;
    }
  `,
);
