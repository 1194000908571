import styled, { css } from 'styled-components';

export const TimerWrapper = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    aspect-ratio: 1/1;
    width: fit-content;
    border-radius: 50%;
    padding: 2rem;
    color: ${theme.colors.dark};
    background-color: ${theme.colors.broomGreen + '80'};
  `,
);

export const TimerBackground = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  transform: scale(1.2);
`;

export const TimerItem = styled.div`
  min-width: 2.5rem;
  text-align: center;
`;

export const TimerItemValue = styled.div`
  font-size: 1.5rem;
`;

export const TimerItemLabel = styled.div`
  font-size: 1rem;
`;
