import {
  PolaroidFrame,
  PolaroidMessage,
  PolaroidPane,
  PolaroidWrapper,
} from './Polaroid.styled';

export type IPolaroidProps = React.ComponentPropsWithRef<'div'> & {
  message?: string;
  rotate?: 'clockwise' | 'counterclockwise';
  src?: string;
};

function Polaroid({
  message,
  rotate,
  src = 'https://placehold.co/79x79?text=404',
  ...otherProps
}: IPolaroidProps) {
  return (
    <PolaroidWrapper rotate={rotate} {...otherProps}>
      <PolaroidPane>
        <PolaroidFrame alt={message} src={src} />
        {message && <PolaroidMessage>{message}</PolaroidMessage>}
      </PolaroidPane>
    </PolaroidWrapper>
  );
}

export default Polaroid;
