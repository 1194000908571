import { RouteProps } from 'react-router-dom';

import useAuth from '../../hooks/useAuth.hook';

import Login from '../../sections/Login/Login.section';

type IPrivateProps = {
  element: RouteProps['element'];
};

function Private({ element }: IPrivateProps) {
  const { user } = useAuth();

  return user ? <>{element}</> : <Login />;
}

export default Private;
