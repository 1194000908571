import styled from 'styled-components';

import Section from '../../components/Section/Section.component';
import Timer from '../../components/Timer/Timer.component';

export const CountdownWrapper = styled(Section)`
  position: relative;
  min-height: auto;
  padding: 4rem 0;
`;

export const CountdownTimer = styled(Timer)`
  margin: 0 auto;
`;
