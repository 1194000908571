import { useState } from 'react';
import { Pagination } from 'swiper';
import Lightbox from 'yet-another-react-lightbox';

import breakpoints from '../../constants/breakpoints.constants';
import slides from '../../constants/slides.constants';

import useBlockScroll from '../../hooks/useBlockScroll.hook';

import baseTheme from '../../styles/baseTheme';
import 'yet-another-react-lightbox/styles.css';

import { SwiperWrapper } from './Swiper.styled';
import SwiperPolaroid from './SwiperPolaroid/SwiperPolaroid.component';
import 'swiper/css/bundle';
import { SwiperSlide } from 'swiper/react';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

function Swiper() {
  const [showLightbox, setShowLightbox] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useBlockScroll(showLightbox);

  return (
    <>
      <Lightbox
        close={() => setShowLightbox(false)}
        index={activeIndex}
        open={showLightbox}
        plugins={[Thumbnails, Zoom]}
        render={{ iconZoomIn: () => null, iconZoomOut: () => null }}
        slides={slides}
        styles={{
          button: { color: baseTheme.colors.brideWhite, filter: 'none' },
          container: { backgroundColor: baseTheme.colors.dark + 'bf' },
          thumbnail: {
            backgroundColor: 'transparent',
            borderColor: baseTheme.colors.brideWhite,
          },
          thumbnailsContainer: {
            backgroundColor: baseTheme.colors.dark + 'bf',
          },
        }}
        thumbnails={{ borderRadius: 0, imageFit: 'cover', vignette: false }}
      />

      <SwiperWrapper
        breakpoints={{ [breakpoints.large.min]: { slidesPerView: 3 } }}
        centeredSlides
        loop
        modules={[Pagination]}
        pagination={{ clickable: true, dynamicBullets: true }}
        slidesPerView={2}
      >
        {slides.map((slide, index) => {
          const openLightbox = () => {
            setActiveIndex(index);
            setShowLightbox(true);
          };

          return (
            <SwiperSlide key={slide.id}>
              <SwiperPolaroid openLightbox={openLightbox} {...slide} />
            </SwiperSlide>
          );
        })}
      </SwiperWrapper>
    </>
  );
}

export default Swiper;
