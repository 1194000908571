import { Fragment } from 'react';

import GuestItem from './GuestItem/GuestItem.component';
import { GuestsSeparator, GuestsWrapper } from './Guests.styled';
import { QueryDocumentSnapshot } from 'firebase/firestore';

type IGuestsProps = {
  guests: QueryDocumentSnapshot[];
};

function Guests({ guests }: IGuestsProps) {
  const compareFn = (
    firstGuest: QueryDocumentSnapshot,
    secondGuest: QueryDocumentSnapshot,
  ) => {
    const firstGuestOrder = firstGuest.data().order || 0;
    const secondGuestOrder = secondGuest.data().order || 0;

    return firstGuestOrder - secondGuestOrder;
  };

  const isGuestListClosed = true;

  return (
    <GuestsWrapper>
      <div>
        {isGuestListClosed
          ? 'La lista se encuentra cerrada. Para editar tu selección comunicate con nosotr@s.'
          : 'Confirmá la asistencia de los miembros de tu grupo.'}
      </div>

      {guests.sort(compareFn).map((guestDoc, index) => {
        const isLastGuest = guests.length - 1 === index;

        return (
          <Fragment key={guestDoc.id}>
            <GuestItem
              guestDoc={guestDoc}
              isGuestListClosed={isGuestListClosed}
            />
            {!isLastGuest && <GuestsSeparator />}
          </Fragment>
        );
      })}
    </GuestsWrapper>
  );
}

export default Guests;
