import styled from 'styled-components';

import Container from '../../components/Container/Container.component';
import {
  SectionInner,
  SectionSubheading,
} from '../../components/Section/Section.styled';

export const ConfirmationContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
`;

export const ConfirmationInner = styled(SectionInner)``;

export const ConfirmationSubheading = styled(SectionSubheading)``;
