import { guestsCollectionRef } from '../services/firebase.service';
import { useCollection } from 'react-firebase-hooks/firestore';

function useGuests(groupCode?: string) {
  const [snapshot] = useCollection(guestsCollectionRef);

  const allGuests = snapshot?.docs;

  const filteredGuests = allGuests?.filter((guestDoc) => {
    return guestDoc.data().group_code === groupCode;
  });

  const validateGroup = (groupCode: string) => {
    const _groupDocs = snapshot?.docs?.filter((guestDoc) => {
      return guestDoc.data().group_code === groupCode;
    });

    return !!_groupDocs?.length;
  };

  return {
    allGuests,
    filteredGuests,
    validateGroup,
  };
}

export default useGuests;
