import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Menu from './components/Menu/Menu.component';
import Private from './components/Private/Private.component';

import routes from './constants/routes.constants';

import GlobalStyle from './styles/GlobalStyle/GlobalStyle.styled';
import baseTheme from './styles/baseTheme';

import AuthProvider from './contexts/Auth.context';

function App() {
  return (
    <ThemeProvider theme={baseTheme}>
      <GlobalStyle />
      <Router>
        <AuthProvider>
          <Menu />
          <Routes>
            {routes.map((route) => (
              <Route
                key={route.id}
                path={route.path}
                element={
                  route.isPrivate ? (
                    <Private element={route.element} />
                  ) : (
                    route.element
                  )
                }
              />
            ))}
          </Routes>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
