import { IPolaroidProps } from '../../Polaroid/Polaroid.component';
import { SwiperPolaroidWrapper } from './SwiperPolaroid.styled';
import { useSwiper, useSwiperSlide } from 'swiper/react';

type ISwiperPolaroidProps = IPolaroidProps & {
  openLightbox: () => void;
};

function SwiperPolaroid({ openLightbox, ...otherProps }: ISwiperPolaroidProps) {
  const slider = useSwiper();
  const { isActive, isNext, isPrev } = useSwiperSlide();

  const onClick = () => {
    if (isActive) {
      openLightbox();
    } else if (isNext) {
      slider.slideNext();
    } else if (isPrev) {
      slider.slidePrev();
    }
  };

  return (
    <SwiperPolaroidWrapper
      isActive={isActive}
      isNext={isNext}
      onClick={onClick}
      {...otherProps}
    />
  );
}

export default SwiperPolaroid;
