import styled, { css } from 'styled-components';

export const RadioWrapper = styled.input(
  ({ theme }) => css`
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
    padding: 0.25rem;
    appearance: none;
    border-radius: 50%;
    border: 1px solid transparent;
    background-color: ${theme.colors.light};

    /* Transitions */
    transition-property: border-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    :focus,
    :focus-visible {
      outline: none;
      border-color: ${theme.colors.broomGreen};
    }

    ::after {
      content: '';
      display: block;
      height: 100%;
      border-radius: inherit;
      border: 2px solid ${theme.colors.light};

      /* Transitions */
      transition-property: background-color;
      transition-duration: inherit;
      transition-timing-function: inherit;
    }

    :checked::after {
      background-color: ${theme.colors.broomGreen};
    }
  `,
);
