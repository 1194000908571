import styled, { css } from 'styled-components';

import Container from '../../components/Container/Container.component';
import Lottie from '../../components/Lottie/Lottie.component';
import Section from '../../components/Section/Section.component';

import devices from '../../constants/devices.constants';

export const PresentationWrapper = styled(Section)(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    min-height: 100vh;
    color: ${theme.colors.brideWhite};

    /* Background */
    background-image: url('/img/bg-mobile-min.jpg');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;

    @media (${devices.large}) or (orientation: landscape) {
      background-image: url('/img/bg-desktop-min.jpg');
      background-position: center;
    }

    ::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: ${theme.colors.charcoal + '80'};
    }
  `,
);

export const PresentationHeader = styled.header`
  position: relative;
  width: 100%;
`;

export const PresentationHeaderContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

export const PresentationDate = styled.small(
  ({ theme }) => css`
    position: relative;
    padding: 0 1rem;
    font-size: 1rem;

    ::before,
    ::after {
      content: '';
      position: absolute;
      top: 50%;
      width: 50%;
      height: 1px;
      background-color: ${theme.colors.brideWhite};
    }

    ::before {
      right: 100%;
    }

    ::after {
      left: 100%;
    }

    @media (${devices.medium}) {
      padding: 0 1.25rem;
      font-size: 1.25rem;
    }
  `,
);

export const PresentationQuote = styled.p(
  ({ theme }) => css`
    margin: 0;
    font-family: ${theme.fonts.parisienne};
    font-size: 2.5rem;
    font-style: italic;
  `,
);

export const PresentationLottie = styled(Lottie)`
  height: 4rem;
`;
