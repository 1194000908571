import { FormGroupWrapper } from './FormGroup.styled';

type IFormGroupProps = React.ComponentPropsWithRef<'div'> & {
  label: string;
};

function FormGroup({ children, label, ...otherProps }: IFormGroupProps) {
  return (
    <FormGroupWrapper {...otherProps}>
      <label>{label}</label>
      {children}
    </FormGroupWrapper>
  );
}

export default FormGroup;
