import styled, { css } from 'styled-components';

import devices from '../../../constants/devices.constants';

import Polaroid from '../../Polaroid/Polaroid.component';

export const SwiperPolaroidWrapper = styled(Polaroid)<{
  isActive: boolean;
  isNext: boolean;
}>(({ isActive, isNext }) => {
  const rotateValue = (isNext ? -2 : 2) + 'deg';

  return css`
    transform: rotate(${rotateValue}) scale(0.75);
    opacity: 0.75;

    /* Transitions */
    transition-property: transform, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    ${isActive &&
    css`
      cursor: zoom-in;
      transform: scale(1);
      opacity: 1;
    `}

    @media (${devices.large}) {
      cursor: pointer;
    }
  `;
});
