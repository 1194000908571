import { useEffect } from 'react';

import { scrollToTop } from '../../helpers/scroll.helpers';

import FadeTransition from '../FadeTransition/FadeTransition.component';
import { SectionWrapper } from './Section.styled';

type ISectionProps = React.ComponentPropsWithRef<'section'>;

function Section({ children, ...otherProps }: ISectionProps) {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <FadeTransition in timeout={600}>
      <SectionWrapper {...otherProps}>{children}</SectionWrapper>
    </FadeTransition>
  );
}

export default Section;
