import Brand from '../../components/Brand/Brand.component';

import scrollDownAnimation from '../../lottie/scroll-down-animation.json';

import {
  PresentationDate,
  PresentationHeader,
  PresentationHeaderContainer,
  PresentationLottie,
  PresentationQuote,
  PresentationWrapper,
} from './Presentation.styled';

function Presentation() {
  return (
    <PresentationWrapper>
      <PresentationHeader>
        <PresentationHeaderContainer>
          <Brand />
          <PresentationDate>10 | 02 | 2024</PresentationDate>
          <PresentationQuote>
            "y todo mi mundo, empieza y termina con vos..."
          </PresentationQuote>
          <PresentationLottie animationData={scrollDownAnimation} loop />
        </PresentationHeaderContainer>
      </PresentationHeader>
    </PresentationWrapper>
  );
}

export default Presentation;
