import styled, { css } from 'styled-components';

export const InputWrapper = styled.input(
  ({ theme }) => css`
    all: inherit;
    padding: 0.5rem;
    border: 1px solid transparent;
    background-color: ${theme.colors.light};

    /* Transitions */
    transition-property: border-color, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    :focus {
      border-color: ${theme.colors.broomGreen};
    }

    :disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  `,
);
