import { DefaultTheme } from 'styled-components';

const baseTheme: DefaultTheme = {
  colors: {
    brideWhite: '#e6ebf0',
    broomGreen: '#7d7869',
    charcoal: '#4b5064',
    dark: '#000000',
    light: '#ffffff',
    redwood: '#55140f',
  },
  fonts: {
    parisienne: "'Parisienne', cursive",
    quicksand: "'Quicksand', sans-serif",
    specialElite: "'Special Elite', cursive",
  },
};

export default baseTheme;
