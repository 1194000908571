import styled, { css } from 'styled-components';

export const GuestsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0;
  padding: 0;
`;

export const GuestsSeparator = styled.hr(
  ({ theme }) => css`
    display: block;
    width: 100%;
    height: 1px;
    margin: 0;
    border: none;
    background-color: ${theme.colors.broomGreen};
  `,
);
