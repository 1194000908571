import styled from 'styled-components';

export const FormGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;

  > label {
    font-size: 14px;
  }
`;
