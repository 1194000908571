import { CountdownTimer, CountdownWrapper } from './Countdown.styled';

function Countdown() {
  const targetDate = new Date('2024-02-10T22:00:00.000Z');

  if (targetDate < new Date()) {
    return null;
  }

  return (
    <CountdownWrapper>
      <CountdownTimer targetDate={targetDate} />
    </CountdownWrapper>
  );
}

export default Countdown;
