import useAuth from '../../hooks/useAuth.hook';

import Button from '../Button/Button.component';
import FormGroup from '../FormGroup/FormGroup.component';
import Input from '../Input/Input.component';
import { LoginFormWrapper } from './LoginForm.styled';

function LoginForm() {
  const { login } = useAuth();

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const email = e.currentTarget.email.value;
    const password = e.currentTarget.password.value;

    try {
      await login?.(email, password);
    } catch (error) {
      console.error('Auth error: ', error);
    }
  };

  return (
    <LoginFormWrapper onSubmit={onSubmit}>
      <FormGroup label="Email">
        <Input name="email" type="email" required />
      </FormGroup>

      <FormGroup label="Contraseña">
        <Input name="password" type="password" required />
      </FormGroup>

      <Button type="submit">Ingresar</Button>
    </LoginFormWrapper>
  );
}

export default LoginForm;
