import Radio from '../../components/Radio/Radio.component';
import Section from '../../components/Section/Section.component';
import { SectionHeading } from '../../components/Section/Section.styled';

import menuOptions from '../../constants/guests.constants';

import useAssistanceFilters, {
  IAssistanceFilterOptionId,
  assistanceFiltersOptionsLabels,
} from '../../hooks/useAssistanceFilters.hook';
import useGuests from '../../hooks/useGuests.hook';

import {
  GuestsListContainer,
  GuestsListInfo,
  GuestsListInfoLabel,
  GuestsListTable,
} from './GuestsList.styled';
import { QueryDocumentSnapshot, setDoc } from 'firebase/firestore';

function GuestsList() {
  const { allGuests } = useGuests();

  const { filteredGuests, selectedOptionId, updateAssistanceFilter } =
    useAssistanceFilters(allGuests);

  const compareFn = (
    firstGuest: QueryDocumentSnapshot,
    secondGuest: QueryDocumentSnapshot,
  ) => {
    const firstGuestGroupNumber = firstGuest.data().group_number;
    const secondGuestGroupNumber = secondGuest.data().group_number;

    const firstGuestOrder = firstGuest.data().order || 0;
    const secondGuestOrder = secondGuest.data().order || 0;

    return (
      firstGuestGroupNumber - secondGuestGroupNumber ||
      firstGuestOrder - secondGuestOrder
    );
  };

  return (
    <Section>
      <GuestsListContainer>
        <SectionHeading withBorder>Lista de invitados</SectionHeading>

        <GuestsListInfo>
          {Object.keys(assistanceFiltersOptionsLabels).map((key) => {
            const optionId = key as IAssistanceFilterOptionId;
            const filteredGuestsCount = filteredGuests[optionId]?.length || 0;
            const isChecked = key === selectedOptionId;

            return (
              <GuestsListInfoLabel
                htmlFor={key}
                isSelected={isChecked}
                key={key}
              >
                <Radio
                  checked={isChecked}
                  id={key}
                  onChange={() => updateAssistanceFilter(optionId)}
                />
                {assistanceFiltersOptionsLabels[optionId]} (
                {filteredGuestsCount})
              </GuestsListInfoLabel>
            );
          })}
        </GuestsListInfo>

        <GuestsListTable>
          <thead>
            <tr>
              <th>Grupo</th>
              <th>Nombre</th>
              <th>Asiste</th>
              <th>Civil</th>
              <th>Opción de menú</th>
            </tr>
          </thead>

          <tbody>
            {filteredGuests[selectedOptionId]
              ?.sort(compareFn)
              .map((guestDoc) => {
                const guestData = guestDoc.data();

                const guestName = guestData.last_name
                  ? guestData.first_name + ' ' + guestData.last_name
                  : guestData.first_name;

                const onChange: React.ChangeEventHandler<
                  HTMLInputElement | HTMLSelectElement
                > = (e) => {
                  e.preventDefault();

                  try {
                    const guestRef = guestDoc.ref;

                    const updatedData = {
                      [e.target.name]:
                        e.target.name === 'civil' ||
                        e.target.name === 'will_assist'
                          ? !guestData[e.target.name]
                          : e.target.value,
                    };

                    setDoc(guestRef, updatedData, { merge: true });
                  } catch (error) {
                    console.error('Error editing document: ', error);
                  }
                };

                return (
                  <tr key={guestDoc.id}>
                    <td>{guestData.group_code}</td>
                    <td>{guestName}</td>
                    <td>
                      <input
                        checked={guestData.will_assist}
                        name="will_assist"
                        onChange={onChange}
                        type="checkbox"
                      />
                    </td>
                    <td>
                      <input
                        checked={guestData.civil}
                        name="civil"
                        onChange={onChange}
                        type="checkbox"
                      />
                    </td>
                    <td>
                      <select
                        defaultValue={guestData.menu_option || ''}
                        name="menu_option"
                        onChange={onChange}
                      >
                        <option value="" disabled>
                          Sin especificar
                        </option>
                        {menuOptions.map((menuOption) => (
                          <option key={menuOption} value={menuOption}>
                            {menuOption}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </GuestsListTable>
      </GuestsListContainer>
    </Section>
  );
}

export default GuestsList;
