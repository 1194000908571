export function setScrollY(offsetY: number, behavior: ScrollBehavior = 'auto') {
  document.documentElement.style.scrollBehavior = behavior;

  if (document.scrollingElement?.scrollTo) {
    document.scrollingElement.scrollTo({ top: offsetY, behavior });
  } else {
    document.body.scrollTop = document.documentElement.scrollTop = offsetY;
  }
}

export function scrollToTop(behavior: ScrollBehavior = 'auto') {
  setScrollY(0, behavior);
}
