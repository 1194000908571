import ConfirmationForm from '../../components/ConfirmationForm/ConfirmationForm.component';
import Lottie from '../../components/Lottie/Lottie.component';
import Section from '../../components/Section/Section.component';
import { SectionHeading } from '../../components/Section/Section.styled';

import signAnimation from '../../lottie/sign-animation.json';

import {
  ConfirmationContainer,
  ConfirmationInner,
  ConfirmationSubheading,
} from './Confirmation.styled';

function Confirmation() {
  return (
    <Section>
      <ConfirmationContainer>
        <SectionHeading withBorder>Confirmación</SectionHeading>

        <ConfirmationInner>
          <Lottie animationData={signAnimation} />
          <ConfirmationSubheading>RSVP</ConfirmationSubheading>
        </ConfirmationInner>

        <ConfirmationForm />
      </ConfirmationContainer>
    </Section>
  );
}

export default Confirmation;
