import styled, { css } from 'styled-components';

import devices from '../../constants/devices.constants';

export const SectionWrapper = styled.section`
  padding-top: 7rem;
  padding-bottom: 6rem;
  text-align: center;
`;

export const SectionHeading = styled.h2<{
  withBorder?: boolean;
  withBackground?: boolean;
}>(
  ({ theme, withBackground, withBorder }) => css`
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding: 1rem 2rem;
    font-family: ${theme.fonts.parisienne};
    font-size: 2.5rem;
    font-weight: normal;

    ${withBorder &&
    css`
      border: 2px solid ${theme.colors.broomGreen};
    `};

    ${withBackground &&
    css`
      background-color: ${theme.colors.broomGreen + '80'};
    `};

    @media (${devices.medium}) {
      max-width: 75%;
    }

    @media (${devices.large}) {
      max-width: 50%;
    }
  `,
);

export const SectionInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const SectionSubheading = styled.h3(
  ({ theme }) => css`
    margin: 0;
    font-size: 1.5rem;
    font-weight: normal;
    color: ${theme.colors.redwood};

    @media (${devices.large}) {
      font-size: 2rem;
    }
  `,
);

export const SectionContent = styled.div`
  font-size: 1rem;

  @media (${devices.large}) {
    font-size: 1.25rem;
  }
`;
