import { FormEventHandler, useState } from 'react';

import menuOptions from '../../../../constants/guests.constants';

import useBlockScroll from '../../../../hooks/useBlockScroll.hook';

import Button from '../../../Button/Button.component';
import DarkOverlay from '../../../DarkOverlay/DarkOverlay.component';
import FadeTransition from '../../../FadeTransition/FadeTransition.component';
import FormGroup from '../../../FormGroup/FormGroup.component';
import Radio from '../../../Radio/Radio.component';
import Select from '../../../Select/Select.component';
import {
  AssistanceOption,
  GuestItemForm,
  GuestItemWrapper,
  GuestName,
  OptionsWrapper,
} from './GuestItem.styled';
import { QueryDocumentSnapshot, setDoc } from 'firebase/firestore';

type IGuestItemProps = React.ComponentPropsWithRef<'li'> & {
  guestDoc: QueryDocumentSnapshot;
  isGuestListClosed: boolean;
};

function GuestItem({
  guestDoc,
  isGuestListClosed,
  ...otherProps
}: IGuestItemProps) {
  const guestData = guestDoc.data();

  const guestName = guestData.last_name
    ? guestData.first_name + ' ' + guestData.last_name
    : guestData.first_name;

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (isGuestListClosed) {
      return;
    }

    const formElement = e.currentTarget;

    try {
      const guestRef = guestDoc.ref;

      const updatedData = {
        menu_option: formElement.menu_option.value,
        will_assist: formElement.will_assist.value === 'true',
      };

      setDoc(guestRef, updatedData, { merge: true });

      closeModal();
    } catch (error) {
      console.error('Error editing document: ', error);
    }
  };

  const isConfirmed =
    guestData.will_assist === true || guestData.will_assist === false;

  const [isOpened, setIsOpened] = useState(false);

  const closeModal = () => setIsOpened(false);
  const openModal = () => setIsOpened(true);

  useBlockScroll(isOpened);

  return (
    <GuestItemWrapper {...otherProps}>
      <GuestName>{guestName}</GuestName>

      {isConfirmed ? (
        <>
          <div>Asistencia: {guestData.will_assist ? 'Sí' : 'No'}</div>
          {guestData.will_assist && <div>Menú: {guestData.menu_option}</div>}
        </>
      ) : (
        <div>Sin confirmar</div>
      )}

      {!isGuestListClosed && (
        <>
          {' '}
          <Button onClick={openModal}>
            {isConfirmed ? 'Editar respuesta' : 'Confirmar asistencia'}
          </Button>
          <FadeTransition positionAbsolute in={isOpened}>
            <DarkOverlay onClickOutside={closeModal}>
              <GuestItemForm onSubmit={onSubmit}>
                <FormGroup label="Asistencia">
                  <OptionsWrapper>
                    <AssistanceOption>
                      <Radio
                        defaultChecked={guestData.will_assist}
                        name="will_assist"
                        type="radio"
                        value="true"
                      />
                      ¡Sí, voy a asistir!
                    </AssistanceOption>

                    <AssistanceOption>
                      <Radio
                        defaultChecked={!guestData.will_assist}
                        name="will_assist"
                        value="false"
                      />
                      No puedo
                    </AssistanceOption>
                  </OptionsWrapper>
                </FormGroup>

                <FormGroup label="Opción de menú">
                  <Select
                    defaultValue={guestData.menu_option || ''}
                    name="menu_option"
                  >
                    <option disabled value="">
                      Sin especificar
                    </option>
                    {menuOptions.map((menuOption) => (
                      <option key={menuOption} value={menuOption}>
                        {menuOption}
                      </option>
                    ))}
                  </Select>
                </FormGroup>

                <Button type="submit" disabled={isGuestListClosed}>
                  Guardar
                </Button>
              </GuestItemForm>
            </DarkOverlay>
          </FadeTransition>
        </>
      )}
    </GuestItemWrapper>
  );
}

export default GuestItem;
