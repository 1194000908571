import toggleAnimation from '../../../lottie/toggle-animation.json';

import {
  IMenuToggleVariant,
  MenuToggleLottie,
  MenuToggleWrapper,
} from './MenuToggle.styled';

type IMenuToggleProps = React.ComponentPropsWithRef<'button'> & {
  variant?: IMenuToggleVariant;
};

function MenuToggle({ variant = 'primary', ...otherProps }: IMenuToggleProps) {
  return (
    <MenuToggleWrapper variant={variant} {...otherProps}>
      <MenuToggleLottie
        animationData={toggleAnimation}
        variant={variant}
        loop
      />
    </MenuToggleWrapper>
  );
}

export default MenuToggle;
