import { createGlobalStyle, css } from 'styled-components';

import devices from '../../constants/devices.constants';

const GlobalStyle = createGlobalStyle(
  ({ theme }) => css`
    html {
      box-sizing: border-box;
    }

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    body {
      font-family: ${theme.fonts.quicksand};
      line-height: 1.5;
      letter-spacing: 1px;
      background-color: ${theme.colors.broomGreen + '40'};
    }

    a {
      text-decoration: none;
      color: inherit;

      /* Transitions */
      transition-property: color border-color;
      transition-duration: 0.3s;
      transition-timing-function: ease;
    }

    button {
      all: unset;
      box-sizing: border-box;
      cursor: default;

      /* Transitions */
      transition-property: background-color opacity;
      transition-duration: 0.3s;
      transition-timing-function: ease;

      @media (${devices.large}) {
        cursor: pointer;
      }
    }
  `,
);

export default GlobalStyle;
