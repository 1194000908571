import Anchor from '../../components/Anchor/Anchor.component';
import Lottie from '../../components/Lottie/Lottie.component';
import Polaroid from '../../components/Polaroid/Polaroid.component';
import Section from '../../components/Section/Section.component';
import { SectionHeading } from '../../components/Section/Section.styled';

import confettiAnimation from '../../lottie/confetti-animation.json';

import {
  CeremonyContainer,
  CeremonyDate,
  CeremonyDatetimeWrapper,
  CeremonyPlaceAddress,
  CeremonyPlaceInner,
  CeremonyPlaceName,
  CeremonyPlaceWrapper,
  CeremonyTime,
} from './Ceremony.styled';

function Ceremony() {
  return (
    <Section>
      <CeremonyContainer>
        <SectionHeading withBorder>Ceremonia</SectionHeading>

        <CeremonyDatetimeWrapper>
          <Lottie animationData={confettiAnimation} />
          <CeremonyDate>10 | Febrero | 2024</CeremonyDate>
          <CeremonyTime>19:00 horas</CeremonyTime>
        </CeremonyDatetimeWrapper>

        <CeremonyPlaceWrapper>
          <Polaroid src="/img/ceremony-min.jpg" rotate="clockwise" />

          <CeremonyPlaceInner>
            <CeremonyPlaceName>Americana Lago Gutiérrez</CeremonyPlaceName>
            <CeremonyPlaceAddress>
              <div>Ruta 40 Sur - Km. 2020</div>
              <div>8400 San Carlos de Bariloche</div>
              <div>Río Negro, Argentina</div>
            </CeremonyPlaceAddress>
            <Anchor
              href="https://goo.gl/maps/qruLCwFocwGnZeZK6"
              rel="noreferrer"
              target="_blank"
            >
              <i className="fa-solid fa-location-dot"></i> Ver mapa
            </Anchor>
          </CeremonyPlaceInner>
        </CeremonyPlaceWrapper>
      </CeremonyContainer>
    </Section>
  );
}

export default Ceremony;
