import { RouteProps } from 'react-router-dom';

import AddGuests from '../sections/AddGuests/AddGuests.section';
import Ceremony from '../sections/Ceremony/Ceremony.component';
import Confirmation from '../sections/Confirmation/Confirmation.component';
import Countdown from '../sections/Countdown/Countdown.section';
import DressCode from '../sections/DressCode/DressCode.section';
import Gifts from '../sections/Gifts/Gifts.component';
import GuestsList from '../sections/GuestsList/GuestsList.component';
import Moments from '../sections/Moments/Moments.section';
import Presentation from '../sections/Presentation/Presentation.section';

export type IRouteItem = RouteProps & {
  isDisabled?: boolean;
  isPrivate?: boolean;
  path: Exclude<RouteProps['path'], undefined>;
  title: string;
};

const routes: IRouteItem[] = [
  {
    element: (
      <>
        <Presentation />
        <Countdown />
      </>
    ),
    id: 'home',
    path: '/',
    title: 'Inicio',
  },
  {
    element: <Ceremony />,
    id: 'ceremony',
    path: '/ceremonia',
    title: 'Ceremonia',
  },
  {
    element: <DressCode />,
    id: 'dresscode',
    path: '/dresscode',
    title: 'Código de vestimenta',
  },
  {
    element: <Gifts />,
    id: 'gifts',
    path: '/regalos',
    title: 'Regalos',
  },
  {
    element: <Confirmation />,
    id: 'confirmation',
    isDisabled: true,
    path: '/confirmacion',
    title: 'Confirmación',
  },
  {
    element: <Moments />,
    id: 'our-moments',
    path: '/nuestros-momentos',
    title: 'Nuestros momentos',
  },
  {
    element: <AddGuests />,
    id: 'add-guests',
    isDisabled: true,
    isPrivate: true,
    path: '/agregar-invitados',
    title: 'Agregar invitados',
  },
  {
    element: <GuestsList />,
    id: 'guests-list',
    isDisabled: true,
    isPrivate: true,
    path: 'lista-de-invitados',
    title: 'Lista de invitados',
  },
];

export default routes.filter((route) => !route.isDisabled);
