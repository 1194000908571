import { useEffect } from 'react';

function useOnClickOutside(
  ref: React.MutableRefObject<HTMLElement | null>,
  onClick: () => void,
) {
  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        onClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClick, ref]);
}

export default useOnClickOutside;
