import { createContext, useEffect, useState } from 'react';

import { auth } from '../services/firebase.service';
import {
  User,
  UserCredential,
  signInWithEmailAndPassword,
} from 'firebase/auth';

type IAuthContext = {
  login?: (email: string, password: string) => Promise<UserCredential>;
  user?: User | null;
};

export const AuthContext = createContext<IAuthContext>({});

type IAuthProviderProps = {
  children: React.ReactNode;
};

function AuthProvider({ children }: IAuthProviderProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsuscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setIsLoading(false);
    });

    return unsuscribe;
  }, []);

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const value = {
    login,
    user,
  };

  return (
    <AuthContext.Provider value={value}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
