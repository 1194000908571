import styled, { css } from 'styled-components';

export const SelectWrapper = styled.select(
  ({ theme }) => css`
    cursor: pointer;
    padding: 0.5rem;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${theme.colors.dark};
    border: 1px solid transparent;
    appearance: none;

    /* Transitions */
    transition-property: border-color, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    :focus-visible {
      outline: none;
      border-color: ${theme.colors.broomGreen};
    }

    :disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  `,
);
