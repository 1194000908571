import styled, { css } from 'styled-components';

import Container from '../../components/Container/Container.component';
import {
  SectionContent,
  SectionInner,
} from '../../components/Section/Section.styled';

import devices from '../../constants/devices.constants';

export const GiftsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
`;

export const GiftsInner = styled(SectionInner)``;

export const GiftsContent = styled(SectionContent)(
  ({ theme }) => css`
    > span {
      color: ${theme.colors.redwood};
    }
  `,
);

export const PaymentMethodsWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    border: 2px solid ${theme.colors.broomGreen};

    @media (${devices.medium}) {
      max-width: 75%;
    }

    @media (${devices.large}) {
      max-width: 50%;
    }
  `,
);
