import { useRef } from 'react';

import useOnClickOutside from '../../hooks/useOnClickOutside.hook';

import { DarkOverlayInner, DarkOverlayWrapper } from './DarkOverlay.styled';

type IDarkOverlayProps = React.ComponentPropsWithRef<'div'> & {
  onClickOutside: () => void;
};

function DarkOverlay({ children, onClickOutside }: IDarkOverlayProps) {
  const innerRef = useRef(null);

  useOnClickOutside(innerRef, () => {
    onClickOutside();
  });

  return (
    <DarkOverlayWrapper>
      <DarkOverlayInner ref={innerRef}>{children}</DarkOverlayInner>
    </DarkOverlayWrapper>
  );
}

export default DarkOverlay;
